import { Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Header, Image, Modal, Icon, Form, Container } from 'semantic-ui-react'
import ASMTextInput from '../../../../utilities/customForm/ASMTextInput'
import RoleService from '../../../../services/roleService'
import { handleErrorResponse } from '../../../../utilities/axiosResponse/AxiosErrorResponse'
import { handleSuccessResponse } from '../../../../utilities/axiosResponse/AxiosSuccessResponse'

export default function CreateRole({roleModify, setRoleModify}) {
  const [isClicked, setIsClicked] = useState(false)
  const [open, setOpen] = useState(false)

  const initalValues = {
    roleName: ''
  }

  async function handleCreateRole({roleName}, resetForm) {
    const roleService = new RoleService();
    setIsClicked(true)
    try {
      const res = await roleService.create(roleName)
      handleSuccessResponse(res)
      resetForm({ values: '' })
      setRoleModify(!roleModify)
    } catch (err) {
      console.log(err);
      handleErrorResponse(err)
    } finally {
      setIsClicked(false)
      setOpen(false)
    }
  }

  return (
    <Formik
      initialValues={initalValues}
      onSubmit={(values, { resetForm }) => handleCreateRole(values, resetForm)}
    >
      {({ handleSubmit, values }) => (
        <Modal
          as='form'
          className='ui form'
          onSubmit={handleSubmit}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={<Button positive floated='right' style={{ marginBottom: '1em' }}><Icon name='plus' /> Create New Role</Button>}
        >
          <Modal.Header>Create A Role</Modal.Header>
          <Modal.Content >
            Rol Adı :
            <ASMTextInput
              name='roleName'
              placeholder='Rol Adı'
              style={{ marginTop: '1em' }}
              value={values.roleName}
            />
          </Modal.Content>
          <div style={{ margin: '2em 1.5em 1em', float: 'right' }}>
            <Button negative onClick={() => setOpen(false)}>İptal Et</Button>
            {!isClicked
              ? <Button positive type='submit' >Oluştur</Button>
              : <Button positive loading disabled>Oluştur</Button>
            }
          </div>
        </Modal>
      )}
    </Formik>
  )
}

import { toast } from 'react-toastify';

const errorToast = (message) => toast.error(message);

export function handleErrorResponse(error) {
    if (error.response) {
        if (error.response.data.message) { // return errorResult
            // alert(error.response.data.message)
            errorToast(error.response.data.message)
        }
        else if (error.response.data.title) { // validation errors
            // alert(error.response.data.title)
            errorToast(error.response.data.title)
        }
        else if (error.response.data.Title) { // internal server error (middleware)
            // alert(error.response.data.Title)
            errorToast(error.response.data.Title)
        }
        else {
            console.error(error, "unhandled error at error.response.data");
        }
    }
    else if (error.message) { // axios error (network)
        console.error(error.message);
        // alert(error.message)
        errorToast(error.message)
    }
    else { // others
        console.error(error)
    }
}

export function handleErrorResponseAndDispatch(error) {
    return (dispatch, method) => {
        if (error.response.data) {
            if (error.response.data.message) { // return errorResult
                dispatch(method(error.response.data.message))
                // alert(error.response.data.message)
                errorToast(error.response.data.message)
            }
            else if (error.response.data.title) { // validation errors
                dispatch(method(error.response.data.title))
                // alert(error.response.data.title)
                errorToast(error.response.data.title)
            }
            else if (error.response.data.Title) { // internal server error (middleware)
                dispatch(method(error.response.data.Title))
                // alert(error.response.data.Title)
                errorToast(error.response.data.Title)
            }
            else {
                dispatch(method(error))
                console.error(error, "unhandled error at error.response.data");
            }
        }
        else if (error.message) { // axios error (network)
            dispatch(method(error.message))
            // alert(error.message)
            errorToast(error.message)
        }
        else { // others
            console.error(error)
            dispatch(method(error.message))
        }
    }
}

import React, { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import './css/FeedStyles.css'
import FeedPosts from '../components/post/FeedPosts'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Helpers/Loader';
import PostService from '../services/postService';
import CreatePost from '../components/post/CreatePost';
import LoginedUserProfile from './LoginedUserProfile';
import UserInformations from '../components/user/UserInformations';

export default function Feed() {
  const dispatch = useDispatch();

  const postState = useSelector(state => state.post);

  useEffect(() => {
    let postService = new PostService();
    postService.getFeedPosts()(dispatch)
  }, [dispatch])

  let loading = postState.loading;

  return (
    <div className='feed'>
      {
        loading
          ? <Loader />
          : <Grid >
            <Grid.Column width={5}>

            </Grid.Column>
            <Grid.Column width={7}>
              <FeedPosts posts={postState.posts} />
            </Grid.Column>
            <Grid.Column width={3}>
              <CreatePost />
            </Grid.Column>
          </Grid>
      }
    </div>
  )
}
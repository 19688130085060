import { FETCH_POSTS_REQUESTED, FETCH_POSTS_SUCCEEDED, FETCH_POSTS_FAILED, CLEAR_POSTS } from '../actions/postAction'
import { posts } from '../initalValues/initals'

const initalState = {
    loading: false,
    posts: posts,
    error: ''
}

export default function postsReducer(state = initalState, { type, payload }) {
    switch (type) {
        case FETCH_POSTS_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case FETCH_POSTS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                posts: payload
            }

        case FETCH_POSTS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_POSTS:
            return {
                ...state,
                posts: {}
            }

        default:
            return state;
    }
}
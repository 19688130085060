import React, { useEffect, useState } from 'react'
import { Button, Card, Comment, Grid, Icon, Image, Modal } from 'semantic-ui-react'
import './css/CreatePost.css'
import { useDispatch, useSelector } from 'react-redux'
import { POST_DETAILS, closePopup, openPopup } from '../../store/actions/popupAction'
import { Link } from 'react-router-dom';
import './css/PostDetails.css'
import { Formik } from 'formik';
import ASMTextInput from '../../utilities/customForm/ASMTextInput';
import CommentService from '../../services/commentService'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse';
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse';


export default function CreatePost({openDetails, setOpenDetails}) {
    const commentService = new CommentService()

    const dispatch = useDispatch();
    const [isClicked, setIsClicked] = useState(false); // create comment
    const [comments, setComments] = useState([]);

    const { loginedUser } = useSelector(state => state.loginedUser)
    const { postDetails } = useSelector(state => state.postDetails)
    const popupState = useSelector(state => state.popup)
    const commentState = useSelector(state => state.comment)

    const initalValues = {
        content: ''
    }

    useEffect(() => {
        getPostComments()
    }, [postDetails, commentState])

    function getPostComments() {
        commentService.getPostComments(postDetails.id)
            .then(res => {
                setComments(res.data.data)
                handleSuccessResponse(res)
            })
            .catch(err => handleErrorResponse(err))
    }

    async function handleCreateComment(values, resetForm) {
        setIsClicked(true)
        try {
            const res = await commentService.createComment(values.content, postDetails.id)
            handleSuccessResponse(res)
            getPostComments(postDetails.id)
            resetForm({ values: '' })
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    function handleDeleteComment(commentId) {
        commentService.deleteComment(commentId)
            .then(res => {
                handleSuccessResponse(res)
                getPostComments(postDetails.id)
            })
            .catch(err => handleErrorResponse(err))
    }

    function handleCloseModal() {
        setOpenDetails(false)
        dispatch(closePopup(POST_DETAILS))
    }

    function handleOpenModal() {
        setOpenDetails(true)
        dispatch(openPopup(POST_DETAILS))
    }

    return (
        <div>
            <Formik
                initialValues={initalValues}
                onSubmit={(values, { resetForm }) => handleCreateComment(values, resetForm)}
            >
                {({ handleSubmit }) => (
                    <Modal
                        as='form'
                        className='ui form'
                        onSubmit={handleSubmit}
                        onClose={() => handleCloseModal(false)}
                        onOpen={() => handleOpenModal(true)}
                        open={openDetails}
                        size='large'
                        style={{backgroundColor: 'rgb(60,50,50)'}}
                    >
                        <Grid>
                            {/* Image */}
                            <Grid.Column width={10} >
                                <Image className='postdetails-image' src={postDetails.imageUrl} size='huge'></Image>
                            </Grid.Column>

                            {/* User Info */}
                            <Grid.Column width={6} className='postdetails-comments'>
                                {postDetails.user && <Card className='comment' style={{ minHeight: '5vh', maxHeight: '25vh' }}>
                                    <Card.Content>
                                        <Link onClick={() => handleCloseModal()}
                                            to={`/profile/${postDetails.user.id}`}
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                alignContent: 'start'
                                            }}>
                                            <Image avatar src={postDetails.user.profilePhotoUrl} size='big'></Image>
                                            <Card.Description content={postDetails.user.login} style={{ fontSize: "15px" }} />
                                        </Link>
                                    </Card.Content>
                                    {postDetails.content &&
                                        <Card.Content extra className='overflow-scroll' style={{ color: 'black' }}>
                                            <span style={{ fontSize: "15px" }}>
                                                {postDetails.content}
                                            </span>
                                        </Card.Content>}
                                </Card>}
                                {/* Comments */}
                                <div style={{ overflow: 'auto', maxHeight: '50vh' }}>
                                    <Comment.Group>
                                        {

                                            comments.map(comment => (
                                                <Card className='comment' key={comment.id}>
                                                    <Comment style={{ backgroundColor: 'white' }}>
                                                        <Comment.Avatar src={comment.userForPostDto.profilePhotoUrl} />
                                                        <Comment.Content>
                                                            <Link
                                                                onClick={() => handleCloseModal()}
                                                                to={`/profile/${comment.userForPostDto.id}`}>
                                                                <Comment.Author as='a'>{comment.userForPostDto.login}</Comment.Author>
                                                            </Link>
                                                            <Comment.Metadata>
                                                                <div>{comment.commentDateInfo}</div>
                                                            </Comment.Metadata>
                                                            <Comment.Text className='overflow-wrap'>{comment.content}</Comment.Text>
                                                            {
                                                                loginedUser.id === comment.userForPostDto.id &&
                                                                <Comment.Actions>
                                                                    <Comment.Action
                                                                        className='delete-comment'
                                                                        onClick={() => handleDeleteComment(comment.id)}
                                                                        style={{
                                                                            color: 'red'
                                                                        }}
                                                                    >
                                                                        Yorumu Sil
                                                                    </Comment.Action>
                                                                </Comment.Actions>
                                                            }

                                                        </Comment.Content>
                                                    </Comment>
                                                </Card>
                                            ))
                                        }
                                    </Comment.Group>
                                </div>


                                {/* Comment Create */}
                                <div className='comment-create' style={{ height: '5vh' }}>
                                    <Grid>
                                        <Grid.Column width={13} className='comment-add'>
                                            <ASMTextInput name="content" placeholder="Yorum Giriniz"></ASMTextInput>
                                        </Grid.Column>
                                        <Grid.Column width={3} className='comment-add' >
                                            {!isClicked && <Button positive type='submit'><Icon name='caret right'></Icon></Button>}
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Modal>
                )}
            </Formik>
        </div >
    )
}

import axios from "axios";
import Cookies from "universal-cookie";
import { handleErrorResponseAndDispatch } from "../utilities/axiosResponse/AxiosErrorResponse";
import { handleSuccessResponseAndDispatch } from '../utilities/axiosResponse/AxiosSuccessResponse'
import { fetchUserMessageGroupsFailed, fetchUserMessageGroupsRequested, fetchUserMessageGroupsSucceeded } from '../store/actions/userMessageGroupsAction'

export default class PostService {
    baseUrl = window.$baseUrl + "UserMessageGroups/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getUserMessageGroups() {
        let newUrl = this.baseUrl + "getusermessagegroups";
        return async (dispatch) => {
            dispatch(fetchUserMessageGroupsRequested())
            await axios({
                method: "get",
                url: newUrl,
                headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchUserMessageGroupsSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchUserMessageGroupsFailed))
        }
    }
}
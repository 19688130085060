import React, { useState } from 'react'
import { Grid, Image, List, Button, Divider, Label, Icon } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import ASMTextInput from '../../utilities/customForm/ASMTextInput'
import ASMPasswordInput from '../../utilities/customForm/ASMPasswordInput'
import ASMImageInput from '../../utilities/customForm/ASMImageInput'
import UserService from '../../services/userService'
import { useDispatch, useSelector } from 'react-redux'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse'

export default function UserInformations() {
    const loginedUserState = useSelector(state => state.loginedUser)
    const userService = new UserService()
    const dispatch = useDispatch();

    const [isClicked, setIsClicked] = useState(false)
    const [isVerifyClicked, setIsVerifyClicked] = useState(false)
    const [isEditProfile, setIsEditProfile] = useState(false)
    const [imgData, setImgData] = useState(null);

    const initalValues = {
        login: loginedUserState.loginedUser.login,
        email: loginedUserState.loginedUser.email,
        oldPassword: "",
        newPassword: "",
        profilePhoto: undefined
    }

    async function handleUserUpdate(updateUserDto) {
        setIsClicked(true)
        try {
            const res = await userService.update(updateUserDto)
            handleSuccessResponse(res)
            userService.getLoginedUserDetails()(dispatch);
            handleCancelOrCompleteUpdate()
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    function handleCancelOrCompleteUpdate() {
        setIsEditProfile(false)
        setImgData(null)
    }

    function handleRemoveProfilePhoto() {
        userService.removeProfilePhoto()
            .then(res => {
                handleSuccessResponse(res)
                userService.getLoginedUserDetails()(dispatch);
            })
            .catch(err => handleErrorResponse(err));
    }

    async function handleResendVerificationMail() {
        setIsVerifyClicked(true)
        try {
            const res = await userService.sendVerificationEmail()
            handleSuccessResponse(res)
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsVerifyClicked(false)
        }
    }

    return (
        <div>
            <Image size='medium' rounded src={loginedUserState.loginedUser.profilePhotoPath} />
            <Divider />
            {
                !isEditProfile
                    ? <Grid textAlign='left' className='profileInfo'>
                        <Grid.Column width={6}>
                            <List>
                                <List.Item>Kullanıcı Adı: </List.Item>
                                <List.Item>Email: </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <List>
                                <List.Item>{loginedUserState.loginedUser.login}</List.Item>
                                <List.Item>{loginedUserState.loginedUser.email}</List.Item>
                            </List>
                        </Grid.Column>
                        {!isEditProfile && <Button onClick={() => setIsEditProfile(true)}>Profili Düzenle</Button>}
                    </Grid>
                    :
                    <div>
                        {!imgData && loginedUserState.loginedUser.profilePhotoPath !== "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" &&
                            <Button color='red' onClick={() => handleRemoveProfilePhoto()}>Remove Profile Photo</Button>}
                        <Formik
                            initialValues={initalValues}
                            onSubmit={(values) => handleUserUpdate(values)}
                        >
                            {({ setFieldValue }) => (
                                <Form className='ui form'>
                                    <ASMImageInput name="profilePhoto" placeholder="Profil Resmi" value="" setFieldValue={setFieldValue}
                                        imgName={"profilePhoto"} imgData={imgData} setImgData={setImgData}></ASMImageInput>
                                    <ASMTextInput name="login" placeholder="Kullanıcı Adı"></ASMTextInput>
                                    {loginedUserState.loginedUser.isEmailVerified
                                        ? <ASMTextInput name="email" placeholder="Email"></ASMTextInput>
                                        : <Grid>
                                            <Grid.Column width={12} style={{ paddingRight: "10px" }}>
                                                <ASMTextInput name="email" placeholder="Email"></ASMTextInput>
                                            </Grid.Column>
                                            <Grid.Column width={4} style={{ paddingLeft: "0px" }}>
                                                {!isVerifyClicked && <Button positive onClick={() => handleResendVerificationMail()} type='button'><Icon name='checkmark'></Icon></Button>}
                                            </Grid.Column>
                                        </Grid>
                                    }
                                    <ASMPasswordInput name="oldPassword" placeholder="Eski Şifre"></ASMPasswordInput>
                                    <ASMPasswordInput name="newPassword" placeholder="Yeni Şifre"></ASMPasswordInput>
                                    <Label style={{ color: "red" }}>*Sadece Değiştirmek istediğiniz kısımları giriniz.</Label>

                                    <Grid columns='two'>
                                        <Grid.Column>
                                            {
                                                !isClicked
                                                    ? <Button positive type='submit'>Uygula</Button>
                                                    : <Button loading disabled positive type='submit'>Uygula</Button>
                                            }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button negative onClick={() => handleCancelOrCompleteUpdate()}>İptal Et</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </div>
    )
}

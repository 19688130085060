import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Grid, Message, Modal } from 'semantic-ui-react'
import ASMTextInput from '../../utilities/customForm/ASMTextInput'
import Timer from '../../Helpers/Timer'
import UserService from '../../services/userService'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import * as Yup from 'yup';


export default function VerificationCode({ openVerification, setOpenVerification, handleSendVerificationMail, email, setIsCodeVerified }) {
    const [isClicked, setIsClicked] = useState(false)
    const [isTimeOut, setIsTimeOut] = useState(false)
    const [resetTimer, setResetTimer] = useState(false)

    const schema = Yup.object().shape({
        verificationCode: Yup.string()
            .required('Verification code is required')
            .matches(/^[0-9]{6}$/, 'Reset code must be exactly 6 digits')
    });

    const codeInital = {
        verificationCode: ''
    }

    async function handleVerificateCode({ verificationCode }) {
        const userService = new UserService();

        setIsClicked(true);
        try {
            const res = await userService.verifyCode({
                email: email,
                verificationCode: verificationCode
            })
            handleSuccessResponse(res)
            setOpenVerification(false)
            setIsCodeVerified(true);
        } catch (err) {
            handleErrorResponse(err)
            console.log(err);
        }
        finally {
            setIsClicked(false)
        }
    }

    function handleResendVerificationCode() {
        handleSendVerificationMail({ email });
        setIsTimeOut(false);
        setResetTimer(!resetTimer);
    }

    return (
        <Formik
            initialValues={codeInital}
            validationSchema={schema}
            onSubmit={handleVerificateCode}
        >
            {({ handleSubmit, values }) => (
                <Modal
                    open={openVerification}
                    onOpen={() => setOpenVerification(true)}
                    onClose={() => setOpenVerification(false)}
                    closeOnDimmerClick={false}
                    as={Form}
                    onSubmit={handleSubmit}
                    className='ui form'
                >
                    <Modal.Header>Doğrulama Kodunuzu Giriniz</Modal.Header>

                    <Modal.Content>
                        <Message info>Mailinize gönderilen doğrulama kodunu giriniz.</Message>
                        <br />
                        Doğrulama Kodu:
                        <Grid>
                            <Grid.Column width={12} verticalAlign='middle'>
                                <ASMTextInput
                                    name='verificationCode'
                                    placeholder='Doğrulama Kodu'
                                    values={values.verificationCode}
                                    maxLength="6"></ASMTextInput>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <Timer setIsTimeOut={setIsTimeOut} resetTimer={resetTimer} />
                                {isTimeOut && <Button secondary onClick={() => handleResendVerificationCode()}>Tekrar Gönder</Button>}

                            </Grid.Column>
                        </Grid>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button negative onClick={() => setOpenVerification(false)}>İptal Et</Button>
                        {
                            !isClicked
                                ? <Button positive type='submit'>Kodu Onayla</Button>
                                : <Button positive loading disabled>Kodu Onayla</Button>
                        }
                    </Modal.Actions>


                </Modal>
            )}
        </Formik>

    )
}

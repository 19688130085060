import React from 'react'
import { Tab } from 'semantic-ui-react'
import ListUsers from '../components/admin-panel/tabs/ListUsers'
import ListRoles from '../components/admin-panel/tabs/ListRoles'


const panes = [
    { menuItem: 'Users', render: () => <Tab.Pane><ListUsers /></Tab.Pane> },
    { menuItem: 'Roles', render: () => <Tab.Pane><ListRoles /></Tab.Pane> },
]


export default function AdminPanel() {
    return (
        <Tab panes={panes}></Tab>
    )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, List } from 'semantic-ui-react'
import UserService from '../../services/userService'
import { Link } from 'react-router-dom'

export default function OtherUsersBasicInformations() {
    const usersBasicInformationsState = useSelector(state => state.usersBasicInformations)
    const dispatch = useDispatch()

    useEffect(() => {
        const userService = new UserService();
        userService.getUsersBasicInformationsForSuggest()(dispatch)
    }, [dispatch])

    return (
        <List>
            {
                usersBasicInformationsState.usersBasicInformations.map(user => (
                    <List.Item key={user.id} style={{ marginBottom: "2em", display: "flex" }}>
                        <Link to={`/profile/${user.id}`}><Image avatar src={user.profilePhotoUrl} /></Link>
                        <List.Content style={{ textAlign: 'left' }}>
                            <List.Header >{user.userName}</List.Header>
                            <List.Description>Description</List.Description>
                        </List.Content>
                    </List.Item>
                ))
            }
        </List>
    )
}

import axios from "axios";
import Cookies from "universal-cookie";

export default class MessageService {
    baseUrl = window.$baseUrl + "Messages/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getOldMessages(groupId) {
        let newUrl = this.baseUrl + "getoldmessages?groupId=" + groupId;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        })
    }

    getNewMessages(groupId) {
        let newUrl = this.baseUrl + "getnewmessages?groupId=" + groupId;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        })
    }
}
import axios from "axios";
import Cookies from "universal-cookie";
import { handleSuccessResponse } from "../utilities/axiosResponse/AxiosSuccessResponse";
import { handleErrorResponse } from "../utilities/axiosResponse/AxiosErrorResponse";

export default class AdminService {
    baseUrl = window.$baseUrl + "Admin/manage/"
    userBaseUrl = this.baseUrl + "user/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getSelectedUser(userId) {
        let newUrl = this.userBaseUrl + "get?userId=" + userId;
        return axios.get(newUrl, this.config);
    }

    getAllUsers() {
        let newUrl = this.userBaseUrl + "getall";
        return axios.get(newUrl, this.config);
    }

    updateUser(user) {
        let newUrl = this.userBaseUrl + "updateuser";
        return axios({
            method: 'patch',
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                id: user.id,
                login: user.login,
                email: user.email,
                roles: user.roles
            }
        })
    }

    sendMail(values){
        console.log(values);
        let newUrl = this.userBaseUrl + "sendmail";
        return axios({
            method: 'post',
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                userId: values.userId,
                subject: values.subject,
                body: values.body
            }
        })  
    }
}
export const USERS_BASIC_INFORMATIONS_REQUESTED = "USERS_BASIC_INFORMATIONS_REQUESTED";
export const USERS_BASIC_INFORMATIONS_SUCCEEDED = "USERS_BASIC_INFORMATIONS_SUCCEEDED";
export const USERS_BASIC_INFORMATIONS_FAILED = "USERS_BASIC_INFORMATIONS_FAILED";

export const CLEAR_USERS_BASIC_INFORMATIONS = "USERS_BASIC_INFORMATIONS";

export function fetchUsersBasicInformationsRequested(){
    return{
        type: USERS_BASIC_INFORMATIONS_REQUESTED,
    }
}

export function fetchUsersBasicInformationsSucceeded(usersBasicInformations){
    return{
        type: USERS_BASIC_INFORMATIONS_SUCCEEDED,
        payload: usersBasicInformations
    }
}

export function fetchUsersBasicInformationsFailed(error){
    return{
        type: USERS_BASIC_INFORMATIONS_FAILED,
        payload: error
    }
}


export function clearUsersBasicInformations(){
    return{
        type: CLEAR_USERS_BASIC_INFORMATIONS
    }
}
import { ADD_POST_DETAILS, CLEAR_POST_DETAILS } from '../actions/postDetailsAction'
import { postDetails } from '../initalValues/initals'

const initalState = {
    postDetails: postDetails
}

export default function postDetailsReducer(state = initalState, { type, payload }) {
    switch (type) {
        case ADD_POST_DETAILS:
            return {
                ...state,
                postDetails: payload
            }

        case CLEAR_POST_DETAILS:
            return {
                ...state,
                postDetails: {}
            }

        default:
            return state
    }
}
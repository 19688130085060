import _ from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Search, Grid, Image, Menu } from 'semantic-ui-react'
import UserService from '../../services/userService'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'

const initialState = {
    loading: false,
    results: [],
    value: '',
}

function exampleReducer(state, action) {
    switch (action.type) {
        case 'CLEAN_QUERY':
            return initialState
        case 'START_SEARCH':
            return { ...state, loading: true, value: action.query }
        case 'FINISH_SEARCH':
            return { ...state, loading: false, results: action.results }
        case 'UPDATE_SELECTION':
            return { ...state, value: action.selection }

        default:
            throw new Error()
    }
}

export function CustomSearch() {
    const [users, setUsers] = useState()
    const [state, dispatch] = React.useReducer(exampleReducer, initialState)
    const { loading, results, value } = state

    let newUsers = [];
    if (users) {
        newUsers = users.map(user => ({userid: user.id, profilephotourl: user.profilePhotoUrl,  title: user.userName }))
    }

    const timeoutRef = React.useRef()
    const handleSearchChange = React.useCallback((e, data) => {
        clearTimeout(timeoutRef.current)
        dispatch({ type: 'START_SEARCH', query: data.value })

        timeoutRef.current = setTimeout(() => {
            if (data.value.length === 0) {
                dispatch({ type: 'CLEAN_QUERY' })
                return
            }

            const re = new RegExp(_.escapeRegExp(data.value), 'i')
            const isMatch = (result) => re.test(result.title)

            dispatch({
                type: 'FINISH_SEARCH',
                results: _.filter(newUsers, isMatch).slice(0, 5),
            })
        }, 300)
    }, [newUsers])

    React.useEffect(() => {
        const userService = new UserService()
        userService.getUsersBasicInformationsForSearch()
            .then(res => setUsers(res.data.data))
            .catch(err => handleErrorResponse(err))


        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [])

    const resultRenderer = (user) => {
        return (
            <Link to={`/profile/${user.userid}`} onClick={() => dispatch({ type: 'CLEAN_QUERY' })}>
                <Grid columns='two' style={{ display: 'flex', alignItems: "center", marginBottom: '5px', marginTop: '5px' }}>
                    <Image avatar wrapped src={user.profilephotourl} />
                    <span style={{ verticalAlign: 'middle', color: 'black', fontSize: '16px' }}>{user.title}</span>
                </Grid>
            </Link>
        )
    }

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Search
                loading={loading}
                onResultSelect={(e, data) => {
                    dispatch({ type: 'UPDATE_SELECTION', selection: '' })
                }

                }
                onSearchChange={handleSearchChange}
                resultRenderer={resultRenderer}
                results={results}
                value={value}
                placeholder='Ara'
            />
        </div>
    )
}
import { useField } from 'formik'
import React, { useState } from 'react'
import { FormField, Grid, Icon, Label } from 'semantic-ui-react'
import './css/PasswordInput.css'


export default function ASMPasswordInput({ ...params }) {
    const [field, meta] = useField(params);
    const hasError = meta.touched && !!meta.error;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormField error={meta.touched && !!meta.error}>
            <Grid>
                <Grid.Column width={14} className='passwordInput'>
                    <input {...field} {...params} type={showPassword ? "text" : "password"} autoComplete='on'></input>
                    {hasError ? <Label pointing basic color='red' content={meta.error}></Label> : null}
                </Grid.Column>

                <Grid.Column width={1} className='passwordShowHide' >
                    {
                        showPassword
                            ? <Icon name='eye slash' onClick={() => setShowPassword(false)} />
                            : <Icon name='eye' onClick={() => setShowPassword(true)} />
                    }
                </Grid.Column>
            </Grid>
        </FormField>
    )
}
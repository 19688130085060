import React, { useEffect } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export default function Timer({setIsTimeOut, resetTimer}) {
    useEffect(() => {

    }, [setIsTimeOut])

    return (
        <div>
            <CountdownCircleTimer
                isPlaying
                key={resetTimer}
                duration={180}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[180, 120, 60, 0]}
                size={100}
                onComplete={() => setIsTimeOut(true)}

            >
                {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
        </div>
    )
}

export const ADD_POST_DETAILS = "ADD_POST_DETAILS";
export const CLEAR_POST_DETAILS = "CLEAR_POST_DETAILS";

export function addPostDetails(post){
    return{
        type: ADD_POST_DETAILS,
        payload: post
    }
}


export function clearPostsDetails(){
    return{
        type: CLEAR_POST_DETAILS
    }
}
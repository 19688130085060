import React, { useEffect, useState } from 'react'
import RoleService from '../../../services/roleService'
import { handleErrorResponse } from '../../../utilities/axiosResponse/AxiosErrorResponse'
import { Button, Confirm, Icon, Image, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import CreateRole from '../pages/role/CreateRole';
import { handleSuccessResponse } from '../../../utilities/axiosResponse/AxiosSuccessResponse';

export default function ListRoles() {
    const roleService = new RoleService();

    const [open, setOpen] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const [roles, setRoles] = useState([]);
        const [roleModify, setRoleModify] = useState(false);
    const [selectedRole, setSelectedRole] = useState();

    useEffect(() => {
        roleService.getAllRolesWithAmount()
            .then(res => setRoles(res.data.data))
            .catch(err => handleErrorResponse(err))
    }, [setRoles, roleModify])

    async function handleDeleteRole(roleId) {
        setIsClicked(true);
        try {
            const res = await roleService.delete(roleId)
            handleSuccessResponse(res)
            setRoleModify(!roleModify)
            setOpen(false)
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    return (
        <div>
            <CreateRole roleModify={roleModify} setRoleModify={setRoleModify} />

            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell collapsing >Manage</Table.HeaderCell>
                        <Table.HeaderCell collapsing >Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        roles.map((role) => (
                            <Table.Row key={role.id}>
                                <Table.Cell>{role.name}</Table.Cell>
                                <Table.Cell>{role.amount}</Table.Cell>
                                <Table.Cell><Button primary as={Link} to={`manage/roles/${role.id}`}>Manage</Button></Table.Cell>
                                <Table.Cell>
                                    <Button negative onClick={() => { setOpen(true); setSelectedRole(role) }}><Icon name='trash'></Icon></Button>

                                </Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </ Table>

            <Confirm
                open={open}
                onCancel={() => setOpen(false)}
                onConfirm={() => handleDeleteRole(selectedRole.id)}
                header='Rolü Sil'
                content='Gerçekten Rolü Silmek İstiyormusunuz ?'
                confirmButton={
                    !isClicked
                        ? <Button primary>Evet</Button>
                        : <Button primary loading disabled>Evet</Button>
                }
                cancelButton='Hayır'
            />
        </div>
    )
}
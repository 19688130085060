import axios from "axios";
import Cookies from "universal-cookie";
import { handleErrorResponse, handleErrorResponseAndDispatch } from "../utilities/axiosResponse/AxiosErrorResponse";
import { handleSuccessResponse, handleSuccessResponseAndDispatch } from '../utilities/axiosResponse/AxiosSuccessResponse'

export default class PostService {
    baseUrl = window.$baseUrl + "Comments/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getPostComments(postId) {
        let newUrl = this.baseUrl + "getpostcomments?postId=" + postId;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        })
    }

    createComment(content, postId) {
        let newUrl = this.baseUrl + "create";
        return axios({
            method: "post",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                content: content,
                postId: postId
            }
        });
    }

    deleteComment(commentId) {
        let newUrl = this.baseUrl + "delete?commentId=" + commentId;
        return axios({
            method: "delete",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        })
    }
}
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import UserService from '../services/userService'
import { handleErrorResponse } from '../utilities/axiosResponse/AxiosErrorResponse'
import { handleSuccessResponse } from '../utilities/axiosResponse/AxiosSuccessResponse'

export default function VerifyEmail() {
    let { token } = useParams()
    const [isVerified, setIsVerified] = useState(null);

    useEffect(() => {
        if (token !== null | undefined) {
            const userService = new UserService()
            userService.verifyEmail(token)
                .then(res => {
                    setIsVerified(true);
                    handleSuccessResponse(res)
                })
                .catch(err => {
                    setIsVerified(false);
                    if (err.response.data.Detail === "Given Data No found") {
                        alert("Geçersiz Token")
                    }
                    else {
                        handleErrorResponse(err)
                    }
                })
        }
    }, [])

    return (
        <div style={{marginTop: '2em'}}>
            {
                isVerified === null
                    ? <div>
                        <h1>Email Doğrulanıyor...</h1>
                        <h5>Email doğrulanıyor. Lütfen Bekleyiniz...</h5>
                    </div>

                    : isVerified === true
                        ? <div>
                            <h1>Email Başarıyla Doğrulandı</h1>
                            <h5>Email başarılı bir şekilde onaylandı.<Link to={'/'}>Buraya</Link> tıklayarak ana sayfaya dönebilirsiniz.</h5>
                        </div>
                        : <div>
                            <h1>Email Doğrulanamadı</h1>
                            <h3>Email doğrulanmış veya token geçersiz. Lütfen profilden yeniden email isteminde bulununuz.</h3>
                        </div>
            }
        </div>
    )
}

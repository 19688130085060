import React, { useEffect, useState } from 'react'
import { Button, Grid, Icon, Image, Modal } from 'semantic-ui-react'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import MessageGroupService from '../../services/messageGroupService'
import UserMessageGroupService from '../../services/userMesageGroupService'
import ASMCheckboxInput from '../../utilities/customForm/ASMCheckboxInput'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import ASMTextInput from '../../utilities/customForm/ASMTextInput'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse'

export default function CreateMessageGroup() {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const initalValues = {
        messageGroupName: '',
        isPublic: false
    }

    async function handleCreateMessageGroup({ messageGroupName, isPublic }, resetForm) {
        const messageGroupService = new MessageGroupService()
        const userMessageGroupService = new UserMessageGroupService()
        setIsClicked(true)
        try {
            const res = await messageGroupService.createMessageGroup(messageGroupName, isPublic)
            userMessageGroupService.getUserMessageGroups()(dispatch)
            handleSuccessResponse(res)
            setOpen(false)
            resetForm({ values: '' })
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }


    return (
        <Formik
            initialValues={initalValues}
            onSubmit={(values, { resetForm }) => handleCreateMessageGroup(values, resetForm)
            }
        >
            {({ handleSubmit, values }) => (
                <Modal
                    as='form'
                    className='ui form'
                    onSubmit={handleSubmit}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    size='small'
                    trigger={<Button positive floated='right' style={{ marginBottom: '1em' }}><Icon name='plus' /> Mesaj Grubu Oluştur</Button>}
                >
                    <Modal.Header>Mesaj Grubu Oluşur</Modal.Header>
                    <Modal.Content >
                        Grup Adı : <ASMTextInput
                            name='messageGroupName'
                            placeholder='Grup Adı'
                            style={{ marginTop: '1em' }}
                            value={values.messageGroupName}
                        />
                        <Grid>
                            <Grid.Column width={3}>Public Chat : </Grid.Column>
                            <Grid.Column width={2}><ASMCheckboxInput name="isPublic" value={values.isPublic} /></Grid.Column>
                        </Grid>
                    </Modal.Content>
                    <div style={{ margin: '2em 1.5em 1em', float: 'right' }}>
                        <Button negative onClick={() => setOpen(false)}>İptal Et</Button>
                        {!isClicked
                            ? <Button positive type='submit' >Oluştur</Button>
                            : <Button positive loading disabled>Oluştur</Button>
                        }
                    </div>
                </Modal>
            )}
        </Formik>
    )
}

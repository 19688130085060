import React, { useState, useEffect } from 'react';
import { Button, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import AuthService from '../services/authService'
import UserService from '../services/userService'
import { Link, useNavigate } from 'react-router-dom';
import logo from "../assets/images/applogo.jpeg"
import background from "../assets/images/background.jpeg"
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik'
import ASMTextInput from '../utilities/customForm/ASMTextInput';
import ASMPasswordInput from '../utilities/customForm/ASMPasswordInput';
import './css/LoginStyles.css'
import * as Yup from 'yup'

export default function Login() {
  const authService = new AuthService();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { auth } = useSelector(state => state.auth);
  useEffect(() => {
    if (Object.keys(auth).length !== 0) {
      authService.writeTokenToCookie(auth.token, auth.expirationDate)
      navigateAndReload()
    }
  }, [dispatch])

  function navigateAndReload() {
    navigate("/")
    window.location.reload()
  }

  const handleLogin = ({ login, password }) => {
    authService.login(login, password)(dispatch)
  };

  const initalLoginValues = {
    login: '',
    password: ''
  }

  const loginSchema = Yup.object().shape({
    login: Yup.string()
      .required('Required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Required')
  })

  return (
    <div className='login' style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed", height: "101.4vh" }}>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
            <Image src={logo} circular /> <span style={{ color: "#2FBDFB" }}>Hesabınıza giriş yapın</span>
          </Header>
          <Formik
            initialValues={initalLoginValues}
            validationSchema={loginSchema}
            onSubmit={(values) => handleLogin(values)}
          >
            <Form className='ui form'>
              <Segment stacked style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                <ASMTextInput name='login' placeholder='Kullanıcı Adı' style={{ color: "#2FBDFB" }} />
                <ASMPasswordInput name='password' placeholder='Şifre' style={{ color: "#2FBDFB" }} autoComplete="on" />
                <Button type='submit' fluid size='large' style={{ background: "#2FBDFB" }}>Giriş yap</Button>
              </Segment>
            </Form>
          </Formik>

          <Message style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            Yeni misiniz ? <Link to={"/register"}>Kaydolun</Link>
          </Message>

          <Message style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            Şifrenizi mi unuttunuz ? Sıfırlamak İçin <Link to={'/resetpassword'}>Tıklayınız.</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  )
}

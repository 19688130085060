import { FETCH_LOGINED_USER_REQUESTED, FETCH_LOGINED_USER_SUCCEEDED, FETCH_LOGINED_USER_FAILED, CLEAR_LOGINED_USER } from '../actions/loginedUserAction'
import { loginedUser } from '../initalValues/initals'

const initalState = {
    loading: false,
    loginedUser: loginedUser,
    error: ''
}

export default function loginedUserReducer(state = initalState, { type, payload }) {
    switch (type) {
        case FETCH_LOGINED_USER_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case FETCH_LOGINED_USER_SUCCEEDED:
            return {
                ...state,
                loading: false,
                loginedUser: payload
            }

        case FETCH_LOGINED_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_LOGINED_USER:
            return {
                ...state,
                loginedUser: {}
            }

        default:
            return state;
    }
}
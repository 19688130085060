import React, { useEffect } from 'react'
import PostService from '../../services/postService';
import { Card, Image, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Helpers/Loader';
import './css/UserPosts.css'
import { confirmAlert } from 'react-confirm-alert';
import { POST_DETAILS, openPopup } from '../../store/actions/popupAction'
import { addPostDetails } from '../../store/actions/postDetailsAction'
import PostDetails from './PostDetails'

export default function UserPosts({ userId }) {
    const dispatch = useDispatch();
    const loginedUserState = useSelector(state => state.loginedUser);
    const postState = useSelector(state => state.post);
    const popupState = useSelector(state => state.popup)


    let loading = postState.loading;
    let isOwnProfile = userId === undefined ? true : false;

    useEffect(() => {
        let postService = new PostService();
        let id = userId !== undefined ? userId : loginedUserState.loginedUser.id;
        if (id !== undefined | null) {
            postService.getUserPosts(id)(dispatch)
        }
    }, [dispatch, userId])


    function handleOpenPopup(postDetails) {
        dispatch(openPopup(POST_DETAILS))
        dispatch(addPostDetails(postDetails))
    }

    function handleDeletePost(postId) {
        const postService = new PostService()
        confirmAlert({
            title: 'İşlemi Onaylayın',
            message: 'Gönderiyi Silmek İstediğinize Emin Minisin ?',
            buttons: [
                {
                    label: 'Evet',
                    onClick: () => postService.deletePost(loginedUserState.loginedUser.id, postId)(dispatch)
                },
                {
                    label: 'Hayır',
                }
            ]
        });
    }

    return (
        <div>
            {
                loading ? <Loader />
                    : postState && postState.posts.length > 0
                        ? postState.posts.map(post => (
                            <Card key={post.id} centered>
                                {isOwnProfile &&
                                    <button className="close" onClick={() => handleDeletePost(post.id)}>
                                        &times;
                                    </button>
                                }
                                <Image onClick={() => handleOpenPopup(post)} className='userPosts' src={post.imageUrl} wrapped ui={false} />
                                <Card.Content>
                                    <Card.Description className='overflow-wrap'>
                                        {post.content}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <Icon name='clock' />{post.postDateInfo}
                                </Card.Content>
                            </Card>
                        ))
                        : <p>Kullanıcının Hiç Postu Yok.</p>
            }

            {popupState.popup === POST_DETAILS && popupState.isOpened && <PostDetails />}
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Image, Icon } from 'semantic-ui-react'
import AuthService from '../../services/authService'
import UserService from '../../services/userService'
import UserMessageGroupService from '../../services/userMesageGroupService'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Helpers/Loader'
import longLogoWhite from '../../assets/images/named-logo.png'
import { CustomSearch } from './Search'
import './css/Navi.css'

export default function Navi({ isAdmin }) {
  const authService = new AuthService();
  const dispatch = useDispatch();

  const loginedUserState = useSelector(state => state.loginedUser)

  useEffect(() => {
    const userService = new UserService();
    const userMessageGroupService = new UserMessageGroupService()

    if (Object.keys(loginedUserState.loginedUser).length === 0) {
      userService.getLoginedUserDetails()(dispatch)
    }
    userMessageGroupService.getUserMessageGroups()(dispatch)
  }, [dispatch, loginedUserState.loginedUser]);


  function handleLogout() {
    authService.logout()(dispatch)
  }

  let loading = loginedUserState.loading;

  return (
    <div>
      {
        loading
          ? <Loader />
          : <Menu inverted>
            <Link to={"/"} style={{ display: "flex", alignItems: "center", marginLeft: "1.5em" }}>
              <Image src={longLogoWhite} style={{ height: "30px" }} ></Image>
            </Link>
            <Menu.Menu position='right'>
              <CustomSearch />
            </Menu.Menu>
            <Menu.Menu position='right'>
              <Menu.Item position='left'>
                <Link to={'/message'}>
                  <Icon name='comment outline'></Icon>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to={`/profile`}>
                  <Image avatar spaced="right" src={loginedUserState.loginedUser.profilePhotoPath} />
                </Link>
                <Dropdown pointing="top right" text={loginedUserState.loginedUser.login}>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/profile" text="Profil" icon="info" />
                    {isAdmin && <Dropdown.Item as={Link} to="/admin" text="Admin Panel" icon="key" className='admin-panel' />}
                    <Dropdown.Item onClick={() => handleLogout()} text="Çıkış Yap" icon="sign-out" />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
      }
    </div>
  )
}
export const FETCH_USER_MESSAGE_GROUPS_REQUESTED = "FETCH_USER_MESSAGE_GROUPS_REQUESTED";
export const FETCH_USER_MESSAGE_GROUPS_SUCCEEDED = "FETCH_USER_MESSAGE_GROUPS_SUCCEEDED";
export const FETCH_USER_MESSAGE_GROUPS_FAILED = "FETCH_USER_MESSAGE_GROUPS_FAILED";

export const CLEAR_USER_MESSAGE_GROUPS = "CLEAR_USER_MESSAGE_GROUPS";

export function fetchUserMessageGroupsRequested(){
    return{
        type: FETCH_USER_MESSAGE_GROUPS_REQUESTED,
    }
}

export function fetchUserMessageGroupsSucceeded(userMessageGroups){
    return{
        type: FETCH_USER_MESSAGE_GROUPS_SUCCEEDED,
        payload: userMessageGroups
    }
}

export function fetchUserMessageGroupsFailed(error){
    return{
        type: FETCH_USER_MESSAGE_GROUPS_FAILED,
        payload: error
    }
}


export function clearUserMessageGroups(){
    return{
        type: CLEAR_USER_MESSAGE_GROUPS
    }
}
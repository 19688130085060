import React, { useEffect, useRef, useState } from 'react'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  ConversationList,
  Avatar,
  Conversation,
  MessageGroup,
  ConversationHeader
} from "@chatscope/chat-ui-kit-react";

import { useDispatch, useSelector } from 'react-redux';
import MessageService from '../services/messageService'
import { handleErrorResponse } from '../utilities/axiosResponse/AxiosErrorResponse';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Cookies from 'universal-cookie';
import CreatePrivateMessage from '../components/message/CreatePrivateMessage';
import CreateMessageGroup from '../components/message/CreateMessageGroup';

let messageId = 0;

export default function DirectMessage() {
  const messageService = new MessageService();
  const cookies = new Cookies();

  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState();
  const [oldMessages, setOldMessages] = useState();
  const [newMessages, setNewMessages] = useState();
  const [connection, setConnection] = useState();

  const { messageGroups } = useSelector(state => state.userMessageGroups)
  const { loginedUser } = useSelector(state => state.loginedUser)

  let oldSelectedGroup = useRef();
  useEffect(() => {
    if (selectedGroup) {
      if (oldSelectedGroup.current) {
        stopConnection(oldSelectedGroup)
      }

      messageService.getOldMessages(selectedGroup.groupId)
        .then(res => setOldMessages(res.data.data))
        .catch(err => handleErrorResponse(err))

      messageService.getNewMessages(selectedGroup.groupId)
        .then(res => setNewMessages(res.data.data))
        .catch(err => handleErrorResponse(err))


      const hubPath = getGroupName(selectedGroup.groupName)
      const localConnection = new HubConnectionBuilder()
        .withUrl(window.$baseUrl + 'hubs/' + hubPath, {
          accessTokenFactory: () => {
            return cookies.get('token')
          }
        })
        .build();
      localConnection.stop()

      setConnection(localConnection)
      startConnection(localConnection)

      oldSelectedGroup.current = localConnection;
    }
  }, [selectedGroup, dispatch])

  function getGroupName(groupName) {
    if (groupName) {
      if (groupName === 'public') {
        return "public-hub"
      }
      else {
        return "group-hub"
      }
    }
  }
  // SignalR hub'ına bağlantı oluştur

  async function startConnection(connection) {
    messageId = 0;
    // Bağlantıyı başlat
    try {
      await connection.start()
    } catch (err) {
      console.error(err, "SignalR Connection Error");
    }

    if (selectedGroup.groupName !== "public") {
      await connection.invoke('JoinGroup', selectedGroup.groupId)
    }

    // Mesajları dinle
    await connection.on('ReceiveMessage', (sender, message) => {
      setOldMessages(prevMessages => [...prevMessages, {
        id: --messageId,
        content: message,
        createDate: new Date().toISOString(),
        sender: {
          id: sender.id,
          profilePhotoUrl: sender.profilePhotoUrl,
          userName: sender.userName
        }
      }])
    });
  }



  async function stopConnection(connection) {
    try {
      await connection.current.stop()
    } catch (err) {
      console.error(err, "SignalR disconnect Error");
    }
  }


  return (
    <div style={{ position: "relative", height: "70vh" }}>

      {/* Send Private Message */}
      <CreateMessageGroup />
      <CreatePrivateMessage setSelectedGroup={setSelectedGroup} />
      <br />
      <br />
      <br />
      <MainContainer>
        {/* Group Informations Side */}
        <Sidebar position="left" scrollable>
          <ConversationList>

            {messageGroups.map(group => {
              // Helper for getting the data of the first participant
              const [avatar, name] = (() => {
                return [<Avatar src={group.photoUrl} />, group.groupName]
              })();

              return (
                <Conversation key={group.groupId}
                  name={name}
                  info={`${group.groupsLastMessage.senderName} : ${group.groupsLastMessage.message}`}
                  unreadCnt={group.unreadCounter}
                  onClick={() => setSelectedGroup(group)}
                >
                  {avatar}
                </Conversation>
              )

            })}
          </ConversationList>
        </Sidebar>


        {/* Chat Side */}
        <ChatContainer>
          {
            selectedGroup && <ConversationHeader>
              <Avatar src={selectedGroup.photoUrl} />
              <ConversationHeader.Content userName={selectedGroup.groupName} />
            </ConversationHeader>
          }

          <MessageList>
            <MessageGroup>
              <MessageGroup.Messages>
                {/* Eski Mesajlar */}
                {
                  oldMessages && oldMessages.map((oldMessage) => {
                    return (
                      <Message key={oldMessage.id}
                        model={{
                          type: "html",
                          message: oldMessage.content,
                          sender: oldMessage.sender.userName,
                        }}
                      >
                        <Avatar src={oldMessage.sender.profilePhotoUrl} />
                        <Message.Header sender={oldMessage.sender.userName} />
                      </Message>
                    )
                  })
                }
                {
                  newMessages && newMessages.map((newMessage) => {
                    return (
                      <Message key={newMessage.id}
                        model={{
                          type: "html",
                          message: newMessage.content,
                          sender: newMessage.sender.userName
                        }}
                      >
                        <Avatar src={newMessage.sender.profilePhotoUrl} />
                        <Message.Header sender={newMessage.sender.userName} />
                      </Message>
                    )
                  })
                }
              </MessageGroup.Messages>
            </MessageGroup>
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={async (message) => {
              await connection.invoke('SendMessage', loginedUser.id, message, selectedGroup.groupName, selectedGroup.isGroup)
            }}

          />
        </ChatContainer>
      </MainContainer>
    </div>
  )
}

import { devToolsEnhancer } from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

const composedEnhancer = compose(
    applyMiddleware(thunk),
    devToolsEnhancer()
);

export function configureStore (){
    return createStore(rootReducer, composedEnhancer);
} 
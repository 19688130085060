import React, { useEffect, useState } from 'react'
import AdminService from '../../../services/adminService'
import { handleErrorResponse } from '../../../utilities/axiosResponse/AxiosErrorResponse'
import { Button, Image, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

export default function ListUsers() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        var adminService = new AdminService();

        adminService.getAllUsers()
            .then(res => setUsers(res.data.data))
            .catch(err => handleErrorResponse(err))
    }, [setUsers])

    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Profile Photo</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>E-mail</Table.HeaderCell>
                    <Table.HeaderCell>Date Joined</Table.HeaderCell>
                    <Table.HeaderCell>Roles</Table.HeaderCell>
                    <Table.HeaderCell>Manage</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    users.map((user) => (
                        <Table.Row key={user.id}>
                            <Table.Cell><Image src={user.profilePhotoPath} avatar></Image></Table.Cell>
                            <Table.Cell>{user.login}</Table.Cell>
                            <Table.Cell>{user.email}</Table.Cell>
                            <Table.Cell>{user.createDate}</Table.Cell>
                            <Table.Cell verticalAlign='top'>
                                {
                                    user.userRoles.map((role, index) => (
                                        <div key={index}>
                                            {role}
                                            <br/>
                                        </div>
                                    ))
                                }
                            </Table.Cell >
                            <Table.Cell><Button primary as={Link} to={`manage/users/${user.id}`}>Manage</Button></Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </ Table>
    )
}

import { useField } from 'formik'
import React from 'react'
import { FormField, Grid, Label } from 'semantic-ui-react'


export default function ASMTextInput({ ...params }) {
    const [field, meta] = useField(params);
    const hasError = meta.touched && !!meta.error;

    return (
        <FormField error={meta.touched && !!meta.error}>
            <Grid>
                <Grid.Column>
                    <input {...field} {...params}></input>
                    {hasError ? (<Label basic color='red' content={meta.error}></Label>) : null}
                </Grid.Column>
            </Grid>
        </FormField>
    )
}
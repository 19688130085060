import React, { useState } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import ASMTextInput from '../../utilities/customForm/ASMTextInput'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

export default function PasswordResetEmailForm({ handleSendVerificationMail, isClicked }) {
    const emailSchema = Yup.object().shape({
        email: Yup.string().email()
    })

    const emailInital = {
        email: '',
    }


    return (
        <Formik
            initialValues={emailInital}
            validationSchema={emailSchema}
            onSubmit={(values, { resetForm }) => handleSendVerificationMail(values, resetForm)}
        >
            <Form className='ui form'>
                <Grid>
                    <Grid.Column width={3} verticalAlign='middle'>Email : </Grid.Column>
                    <Grid.Column width={10}><ASMTextInput name='email' placeholder='youremail@example.com'></ASMTextInput></Grid.Column>
                </Grid>

                <div style={{ marginTop: '2em', float: 'right' }}>
                    <Button secondary as={Link} to={'/'}>Giriş Ekranına Dön</Button>
                    {
                        !isClicked
                            ? <Button primary type='submit'>Onay Kodu Gönder</Button>
                            : <Button primary loading disabled>Onay Kodu Gönder</Button>
                    }
                </div>
            </Form>
        </Formik>

    )
}

import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { FormField, Grid, Label, Select } from 'semantic-ui-react';

export default function ASMSelectInput({ ...params }) {
    const [field, meta, helpers] = useField(params);
    const hasError = meta.touched && !!meta.error;

    const options = params.options;

    return (
        <FormField error={meta.touched && !!meta.error}>
            <Grid>
                <Grid.Column>
                    <Select multiple {...field} {...params} 
                    options={options} 
                    onBlur={field.onBlur}
                    onChange={(event, data) => {
                        helpers.setValue(data.value)
                    }} />
                    {hasError ? (<Label pointing basic color='red' content={meta.error}></Label>) : null}
                </Grid.Column>
            </Grid>
        </FormField>
    )
};
import axios from "axios";
import Cookies from "universal-cookie";
import { fetchPostsFailed, fetchPostsRequested, fetchPostsSucceeded } from "../store/actions/postAction";
import { handleErrorResponse, handleErrorResponseAndDispatch } from "../utilities/axiosResponse/AxiosErrorResponse";
import { handleSuccessResponse, handleSuccessResponseAndDispatch } from '../utilities/axiosResponse/AxiosSuccessResponse'

export default class PostService {
    baseUrl = window.$baseUrl + "Posts/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getFeedPosts() {
        let newUrl = this.baseUrl + "getfeedposts";
        return async (dispatch) => {
            dispatch(fetchPostsRequested())
            await axios({
                method: "get",
                url: newUrl,
                headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchPostsSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchPostsFailed))
        }
    }

    getUserPosts(userId) {
        let newUrl = this.baseUrl + "getuserposts?userId=" + userId;
        return async (dispatch) => {
            dispatch(fetchPostsRequested())
            await axios({
                method: "get",
                url: newUrl,
                headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchPostsSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchPostsFailed))
        }
    }

    createPost(content, image) {
        let newUrl = this.baseUrl + "createpost";
        var bodyFormData = new FormData();
        bodyFormData.append('image', image);
        bodyFormData.append('content', content);
        return axios({
            method: "post",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: bodyFormData
        });
    }

    deletePost(userId,postId) {
        let newUrl = this.baseUrl + "deletepost?postId=" + postId;
        return async (dispatch) => {
            axios({
                method: "delete",
                url: newUrl,
                headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            })
                .then(res => {
                    handleSuccessResponse(res)
                    this.getUserPosts(userId)(dispatch)
                })
                .catch(err => handleErrorResponse(err))
        }
    }
}
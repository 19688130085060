import axios from "axios";
import Cookies from "universal-cookie";

export default class MessageGroupService {
    baseUrl = window.$baseUrl + "MessageGroups/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    createMessageGroup(messageGroupName, isPublic) {
        let newUrl = this.baseUrl + "createmessagegroup";
        return axios({
            method: "post",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                name: messageGroupName,
                isGroup: true,
                isPublic: isPublic
            }
        })
    }

    createPrivateMessage(targetUserId) {
        let newUrl = this.baseUrl + "createprivatemessage?targetUserId=" + targetUserId;
        return axios({
            method: "post",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        })
    }
}
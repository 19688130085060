import { AUTH_REQUESTED, AUTH_SUCCEEDED, AUTH_FAILED, CLEAR_AUTH } from '../actions/authAction'
import { auth } from '../initalValues/initals'

const initalState = {
    loading: false,
    auth: auth,
    error: ''
}

export default function authReducer(state = initalState, { type, payload }) {
    switch (type) {
        case AUTH_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case AUTH_SUCCEEDED:
            return {
                ...state,
                loading: false,
                auth: payload
            }

        case AUTH_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_AUTH:
            return {
                ...state,
                auth: {}
            }

        default:
            return state;
    }
}
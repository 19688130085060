// Popup-Types
export const CREATE_POST = "CREATE_POST";
export const POST_DETAILS = "POST_DETAILS";


export const POPUP_OPENED = "POPUP_OPENED";
export const POPUP_CLOSED = "POPUP_CLOSED";

export function openPopup(popup){
    return{
        type: POPUP_OPENED,
        payload: popup
    }
}

export function closePopup(popup){
    return{
        type: POPUP_CLOSED,
        payload: popup
    }
}
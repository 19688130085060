export const FETCH_POSTS_REQUESTED = "FETCH_POSTS_REQUESTED";
export const FETCH_POSTS_SUCCEEDED = "FETCH_POSTS_SUCCEEDED";
export const FETCH_POSTS_FAILED = "FETCH_POSTS_FAILED";

export const CLEAR_POSTS = "CLEAR_POSTS";

export function fetchPostsRequested(){
    return{
        type: FETCH_POSTS_REQUESTED,
    }
}

export function fetchPostsSucceeded(posts){
    return{
        type: FETCH_POSTS_SUCCEEDED,
        payload: posts
    }
}

export function fetchPostsFailed(error){
    return{
        type: FETCH_POSTS_FAILED,
        payload: error
    }
}


export function clearPosts(){
    return{
        type: CLEAR_POSTS
    }
}
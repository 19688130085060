import { CLEAR_USER_MESSAGE_GROUPS, FETCH_USER_MESSAGE_GROUPS_FAILED, FETCH_USER_MESSAGE_GROUPS_REQUESTED, FETCH_USER_MESSAGE_GROUPS_SUCCEEDED } from '../actions/userMessageGroupsAction'
import { usersMessageGroups } from '../initalValues/initals'

const initalState = {
    loading: false,
    messageGroups: usersMessageGroups,
    error: ''
}

export default function userMessageGroupsReducer(state = initalState, { type, payload }) {
    switch (type) {
        case FETCH_USER_MESSAGE_GROUPS_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case FETCH_USER_MESSAGE_GROUPS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                messageGroups: payload
            }

        case FETCH_USER_MESSAGE_GROUPS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_USER_MESSAGE_GROUPS:
            return {
                ...state,
                messageGroups: []
            }
        default:
            return state;
    }

}
import { USERS_BASIC_INFORMATIONS_REQUESTED, USERS_BASIC_INFORMATIONS_SUCCEEDED, USERS_BASIC_INFORMATIONS_FAILED, CLEAR_USERS_BASIC_INFORMATIONS} from '../actions/allUsersBasicInformationsAction'
import { usersBasicInformations } from '../initalValues/initals'

const initalState = {
    loading: false,
    usersBasicInformations: usersBasicInformations,
    error: ''
}

export default function usersBasicInformationsReducer(state = initalState, { type, payload }) {
    switch (type) {
        case USERS_BASIC_INFORMATIONS_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case USERS_BASIC_INFORMATIONS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                usersBasicInformations: payload
            }

        case USERS_BASIC_INFORMATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_USERS_BASIC_INFORMATIONS:
            return {
                ...state,
                usersBasicInformations: {}
            }

        default:
            return state;
    }
}
import React, { useState } from 'react'
import { Header } from 'semantic-ui-react'
import VerificationCode from '../components/passwordReset/VerificationCode';
import UserService from '../services/userService'
import { handleSuccessResponse } from '../utilities/axiosResponse/AxiosSuccessResponse';
import { handleErrorResponse } from '../utilities/axiosResponse/AxiosErrorResponse';
import PasswordResetEmailForm from '../components/passwordReset/PasswordResetEmailForm';
import PasswordResetForm from '../components/passwordReset/PasswordResetForm';


export default function ResetPassword() {
  const [isClicked, setIsClicked] = useState(false)
  const [openVerification, setOpenVerification] = useState(false)
  const [isCodeVerified, setIsCodeVerified] = useState(false)
  const [email, setEmail] = useState('')

  async function handleSendVerificationMail({ email }, resetForm) {
    const userService = new UserService();
    setIsClicked(true)
    try {
      const res = await userService.sendPasswordVerificationCode(email)
      handleSuccessResponse(res)
      setEmail(email)
      setOpenVerification(true)
      resetForm({ values: '' })
    } catch (err) {
      handleErrorResponse(err)
      console.log(err);
    }
    finally {
      setIsClicked(false)
    }
  }



  // Formikler ayrı ayrı component olarak yazılacak
  return (
    <div style={{ marginTop: '2em' }}>
      <Header size='huge'>Şifreni Sıfırla</Header>
      {
        !isCodeVerified
          ? <PasswordResetEmailForm
            handleSendVerificationMail={handleSendVerificationMail}
            isClicked={isClicked}
          />

          : <PasswordResetForm
            email={email}
          />


      }



      {openVerification && <VerificationCode
        openVerification={openVerification}
        setOpenVerification={setOpenVerification}
        handleSendVerificationMail={handleSendVerificationMail}
        email={email}
        setIsCodeVerified={setIsCodeVerified}
      />}
    </div>
  )
}

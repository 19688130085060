export const AUTH_REQUESTED = "AUTH_REQUESTED";
export const AUTH_SUCCEEDED = "AUTH_SUCCEEDED";
export const AUTH_FAILED = "AUTH_FAILED";

export const CLEAR_AUTH = "CLEAR_AUTH";

export function authRequested(){
    return{
        type: AUTH_REQUESTED,
    }
}

export function authSucceeded(user){
    return{
        type: AUTH_SUCCEEDED,
        payload: user
    }
}

export function authFailed(error){
    return{
        type: AUTH_FAILED,
        payload: error
    }
}


export function clearAuth(){
    return{
        type: CLEAR_AUTH
    }
}
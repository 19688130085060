import { useField } from 'formik'
import React, { useState } from 'react'
import { FormField, Grid, Image, Label } from 'semantic-ui-react'
import Resizer from "react-image-file-resizer";


export default function ASMImageInput({ setFieldValue, imgName, imgData, setImgData, ...params }) {
    const [field, meta] = useField(params);

    const MAX_FILES = 1;
    const [picture, setPicture] = useState(null);


    const resizeImage = (imageFile) => {
        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                580,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                "file",
                290,
                580
            );
        });
    };
    const handleImageResize = async (event) => {
        const resizedImage = await resizeImage(event.target.files[0]);
        return resizedImage;
    };

    async function onChangePicture(e, setFieldValue) {
        if (e.target.files.length > MAX_FILES) {
            e.preventDefault();
            alert(`En fazla ${MAX_FILES} dosya yükleyebilirsiniz.`);
        }
        else if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            let resizedImage = await handleImageResize(e)


            reader.readAsDataURL(resizedImage); // resizedImage
            setFieldValue(imgName, resizedImage); // e.target.files[0]
        }
    };

    const imageSize = window.innerHeight <= 550 ? 'small' : 'medium'
    const hasError = meta.touched && !!meta.error;

    return (
        <FormField error={meta.touched && !!meta.error}>
            <Grid>
                <Grid.Column>
                    {imgData && <Image size={imageSize} className='tempImage' src={imgData} centered />}
                    <input {...field} {...params} type='file' accept=".jpg, .jpeg, .png" onChange={(event) => onChangePicture(event, setFieldValue)}></input>
                    {hasError ? (<Label pointing basic color='red' content={meta.error} ></Label>) : null}
                </Grid.Column>
            </Grid>
        </FormField>
    )
}
import axios from "axios";
import Cookies from "universal-cookie";

export default class RoleService {
    baseUrl = window.$baseUrl + "Roles/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getAllRoles() {
        let newUrl = this.baseUrl + "getall";
        return axios.get(newUrl, this.config)
    }

    getAllRolesWithAmount() {
        let newUrl = this.baseUrl + "getallwithamount";
        return axios.get(newUrl, this.config)
    }

    getRoleForManage(roleId){
        let newUrl = this.baseUrl + "getroleformanage?roleId="+ roleId;
        return axios.get(newUrl, this.config)
    }

    create(roleName) {
        let newUrl = this.baseUrl + "create";
        return axios({
            method: 'post',
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                name: roleName
            }
        })
    }

    delete(roleId) {
        let newUrl = this.baseUrl + "delete?roleId=" + roleId;
        return axios.delete(newUrl, this.config);
    }
}
import { combineReducers } from 'redux'
import authReducer from './reducers/authReducer';
import postsReducer from './reducers/postReducer';
import loginedUserReducer from './reducers/loginedUserReducer'
import userReducer from './reducers/userReducer'
import popupReducer from './reducers/popupReducer'
import usersBasicInformationsReducer from './reducers/allUsersBasicInformationsReducer';
import postDetailsReducer from './reducers/postDetailsReducer';
import userMessageGroupsReducer from './reducers/userMessageGroupsReducer';

const rootReducer = combineReducers({
    loginedUser: loginedUserReducer,
    auth: authReducer,
    user: userReducer,
    post: postsReducer,
    popup: popupReducer,
    usersBasicInformations: usersBasicInformationsReducer,
    postDetails: postDetailsReducer,
    userMessageGroups: userMessageGroupsReducer
})

export default rootReducer;
import React, { useState } from 'react'
import { Card, Image, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './css/FeedPosts.css'
import { useDispatch, useSelector } from 'react-redux'
import { POST_DETAILS, openPopup } from '../../store/actions/popupAction'
import { addPostDetails } from '../../store/actions/postDetailsAction'
import PostDetails from './PostDetails'

export default function FeedPosts({ posts }) {
    const dispatch = useDispatch();
    const popupState = useSelector(state => state.popup)

    const [openDetails, setOpenDetails] = useState()


    function handleOpenPopup(postDetails) {
        setOpenDetails(true)
        dispatch(openPopup(POST_DETAILS))
        dispatch(addPostDetails(postDetails))
    }

    return (
        <div>
            {
                posts && posts.length > 0
                    ? posts.map(post => (
                        <Card key={post.id} centered>
                            <Card.Content extra textAlign='left'>
                                <Link to={`/profile/${post.user.id}`} style={{ display: 'inline-flex', alignItems: 'center', alignContent: 'start' }}>
                                    <Image avatar src={post.user.profilePhotoUrl}></Image>
                                    <Card.Description content={post.user.login} />
                                </Link>
                            </Card.Content>
                            <Image onClick={() => handleOpenPopup(post)} className='postImage' src={post.imageUrl} wrapped ui={false} size='medium' />
                            <Card.Content>
                                <Card.Description textAlign='left' className='overflow-wrap'>
                                    {post.content}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra textAlign='right'>
                                <Icon name='clock' />{post.postDateInfo}
                            </Card.Content>
                        </Card>
                    ))
                    : <p>Hiç Post Yok.</p>
            }
            {openDetails && <PostDetails openDetails={openDetails} setOpenDetails={setOpenDetails}/>}
        </div>
    )
}

import { POPUP_OPENED, POPUP_CLOSED } from '../actions/popupAction'

const initalState = {
    isOpened: false,
    popup: ''
}

export default function popupReducer(state = initalState, { type, payload }) {
    switch (type) {
        case POPUP_OPENED:
            return {
                ...state,
                isOpened: true,
                popup: payload
            }

        case POPUP_CLOSED:
            return {
                ...state,
                isOpened: false,
                popup: payload
            }

        default:
            return state;
    }
}
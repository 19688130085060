import axios from "axios";
import Cookies from 'universal-cookie';
import { authRequested, authSucceeded, authFailed, clearAuth } from '../store/actions/authAction'
import { clearLoginedUser } from "../store/actions/loginedUserAction";
import { handleErrorResponseAndDispatch } from "../utilities/axiosResponse/AxiosErrorResponse";
import { handleSuccessResponseAndDispatch } from '../utilities/axiosResponse/AxiosSuccessResponse'

export default class AuthService {
    cookies = new Cookies();
    baseUrl = window.$baseUrl + "Auth/"

    register(login, email, password) {
        let newUrl = this.baseUrl + "register";
        return async (dispatch) => {
            dispatch(authRequested())
            axios({
                method: "post",
                url: newUrl,
                data: {
                    login: login,
                    email: email,
                    password: password
                }
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, authSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, authFailed))
        }

    }

    login(login, password) {
        let newUrl = this.baseUrl + "login";
        return async (dispatch) => {
            dispatch(authRequested())
            await axios({
                method: "post",
                url: newUrl,
                data: {
                    login: login,
                    password: password
                }
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, authSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, authFailed))
        }
    }

    verify() {
        let newUrl = this.baseUrl + "verify";
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    verifyAdmin() {
        let newUrl = this.baseUrl + "verifyadmin";
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }



    writeTokenToCookie(token, expDate) {
        let expireDate = new Date(expDate);
        this.cookies.set("token", token, { path: "/", expires: expireDate })
    }

    isAuthenticated() {
        return new Promise((resolve, reject) => {
            if (this.isCookieHasToken()) {
                this.verify().subscribe((res) => {
                    if (res.status === 200) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                },
                    (errorRes) => {
                        resolve(false);
                    })
            }
            else {
                resolve(false);
            }
        });
    }

    isCookieHasToken() {
        if (this.cookies.get("token") === undefined || this.cookies.get("token") === null) {
            return false;
        }
        else {
            return true;
        }
    }

    logout() {
        this.cookies.remove("token")
        window.location.replace("/")
        return dispatch => {
            dispatch(clearAuth())
            dispatch(clearLoginedUser())
        }
    }

}
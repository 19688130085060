import React, { useEffect, useState } from 'react'
import { Grid, Image, List, Divider } from 'semantic-ui-react'
import UserService from '../services/userService'
import "./css/ProfileStyles.css"
import { useParams } from 'react-router-dom'
import UserPosts from '../components/post/UserPosts'
import { useDispatch, useSelector } from 'react-redux'
import OtherUsersBasicInformations from '../components/user/OtherUsersBasicInformations'


// profil kısmı - posts kısmı - sağ kısım 3 farklı parçaya bölünecek
export default function UserProfile() {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)

  let { userId } = useParams()

  useEffect(() => {
    const userService = new UserService()
    userService.getUserDetails(userId)(dispatch)

  }, [userId, dispatch]);

  return (
    <div>
      <Grid>
        <Grid.Column width={4}>
          <Image size='medium' rounded src={user.profilePhotoPath} />
          <Divider />
          <Grid textAlign='left' className='profileInfo'>
            <Grid.Column width={6}>
              <List>
                <List.Item>Kullanıcı Adı: </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={10}>
              <List>
                <List.Item>{user.login}</List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={9}>
          <UserPosts userId={userId} />
        </Grid.Column>
        <Grid.Column width={3}>
          Buraya ne gelse bilemedim
        </Grid.Column>
      </Grid >
    </div >
  )
}



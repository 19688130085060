import { useField } from 'formik'
import React from 'react'
import { FormField, Grid, Label } from 'semantic-ui-react'

export default function ASMCheckboxInput({ ...params }) {
    const [field, meta] = useField(params);
    const hasError = meta.touched && !!meta.error;

    return (
        <FormField error={hasError}>
            <Grid>
                <Grid.Column>
                    <input type='checkbox' {...field} {...params}></input>
                    {hasError ? (<Label basic color='red' content={meta.error}></Label>) : null}
                </Grid.Column>
            </Grid>
        </FormField>
    )
}

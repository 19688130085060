import React, { useEffect, useState } from 'react'
import { Button, Grid, Icon, Image, Modal } from 'semantic-ui-react'
import UserService from '../../services/userService'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import MessageGroupService from '../../services/messageGroupService'
import UserMessageGroupService from '../../services/userMesageGroupService'
import { useDispatch } from 'react-redux'

export default function CreatePrivateMessage({ setSelectedGroup }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        const userService = new UserService()
        userService.getUsersBasicInformationsForSearch()
            .then(res => setUsers(res.data.data))
            .catch(err => handleErrorResponse(err))
    }, [])


    async function handleCreatePrivateChat(targetUserId) {
        const messageGroupService = new MessageGroupService()
        const userMessageGroupService = new UserMessageGroupService()
        setIsClicked(true)
        try {
            const res = await messageGroupService.createPrivateMessage(targetUserId);
            userMessageGroupService.getUserMessageGroups()(dispatch)
            setSelectedGroup(res.data.data)
            setOpen(false)
        }
        catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }

    }
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Button primary floated='right' style={{ marginBottom: '1em' }}><Icon name='plus' /> Özel Mesaj Gönder</Button>}
        >
            <Modal.Header>Yeni Özel Mesaj Gönder</Modal.Header>
            <Modal.Content >
                {
                    users && users.map((user) => (
                        <Grid key={user.id} padded >
                            <Grid.Column width={3}><Image avatar src={user.profilePhotoUrl} /></Grid.Column>
                            <Grid.Column width={8}>{user.userName}</Grid.Column>
                            <Grid.Column width={4} floated='right'>
                                {
                                    !isClicked
                                        ? <Button positive size='mini' onClick={() => handleCreatePrivateChat(user.id)}><Icon name='caret right' /></Button>
                                        : <Button positive disabled loading size='mini' ><Icon name='caret right' /></Button>

                                }
                            </Grid.Column>
                        </Grid>
                    ))
                }

            </Modal.Content>
            <div style={{ margin: '2em 1.5em 1em', float: 'right' }}>
                <Button negative onClick={() => setOpen(false)}>İptal Et</Button>
                {!isClicked
                    ? <Button positive type='submit' >Oluştur</Button>
                    : <Button positive loading disabled>Oluştur</Button>
                }
            </div>
        </Modal>
    )
}

import axios from "axios";
import Cookies from "universal-cookie";
import { fetchLoginedUserRequested, fetchLoginedUserSucceeded, fetchLoginedUserFailed } from '../store/actions/loginedUserAction'
import { fetchUserFailed, fetchUserRequested, fetchUserSucceeded } from '../store/actions/userAction'
import { fetchUsersBasicInformationsRequested, fetchUsersBasicInformationsSucceeded, fetchUsersBasicInformationsFailed } from '../store/actions/allUsersBasicInformationsAction'
import { handleErrorResponseAndDispatch } from "../utilities/axiosResponse/AxiosErrorResponse";
import { handleSuccessResponseAndDispatch } from "../utilities/axiosResponse/AxiosSuccessResponse";

export default class UserService {
    // dispatch = useDispatch();

    baseUrl = window.$baseUrl + "Users/"
    cookies = new Cookies();

    config = {
        headers: { Authorization: `Bearer ${this.cookies.get("token")}` }
    };

    getLoginedUserDetails() {
        let newUrl = this.baseUrl + "getlogineduser";
        return async (dispatch) => {
            dispatch(fetchLoginedUserRequested())
            await axios.get(newUrl, this.config)
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchLoginedUserSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchLoginedUserFailed)) // dispatch(fetchLoginedUserFailed(err.message))
        }
    }

    getUserDetails(userId) {
        let newUrl = this.baseUrl + "getuser?userId=" + userId;
        return async (dispatch) => {
            dispatch(fetchUserRequested())
            await axios({
                method: "get",
                url: newUrl,
                headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            })
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchUserSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchUserFailed))
        }
    }

    getUsersBasicInformationsForSuggest() {
        let newUrl = this.baseUrl + "getusersbasicinformationsforsuggest";
        return async (dispatch) => {
            dispatch(fetchUsersBasicInformationsRequested())
            await axios.get(newUrl, this.config)
                .then(res => handleSuccessResponseAndDispatch(res)(dispatch, fetchUsersBasicInformationsSucceeded))
                .catch(err => handleErrorResponseAndDispatch(err)(dispatch, fetchUsersBasicInformationsFailed)) // dispatch(fetchLoginedUserFailed(err.message))
        }
    }

    getUsersBasicInformationsForSearch() {
        let newUrl = this.baseUrl + "getallusersbasicinformationsforsearch";
        return axios.get(newUrl, this.config)
    }


    update(userUpdateDto) {
        let newUrl = this.baseUrl + "update";
        var bodyFormData = new FormData();
        bodyFormData.append('login', userUpdateDto.login);
        bodyFormData.append('email', userUpdateDto.email);
        bodyFormData.append('oldPassword', userUpdateDto.oldPassword);
        bodyFormData.append('newPassword', userUpdateDto.newPassword);
        bodyFormData.append('profilePhoto', userUpdateDto.profilePhoto);
        return axios({
            method: "patch",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: bodyFormData
        });
    }

    removeProfilePhoto() {
        let newUrl = this.baseUrl + "removeprofilephoto";
        return axios({
            method: "patch",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    verifyEmail(token) {
        let newUrl = this.baseUrl + "verifyemail?token=" + token;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    sendVerificationEmail() {
        let newUrl = this.baseUrl + "sendverificationemail";
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    sendPasswordVerificationCode(email) {
        let newUrl = this.baseUrl + "sendpasswordresetverificationcode?email=" + email;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    verifyCode(values){
        let newUrl = this.baseUrl + `verifyresetpasswordverificationcode?email=${values.email}&&verificationCode=${values.verificationCode}`;
        return axios({
            method: "get",
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
        });
    }

    resetPassword(values){
        let newUrl = this.baseUrl + "resetpassword";
        return axios({
            method: 'patch',
            url: newUrl,
            headers: { "Authorization": `Bearer ${this.cookies.get("token")}` },
            data: {
                email: values.email,
                newPassword: values.newPassword,
                newPasswordConfirm: values.newPasswordConfirm
            }
        })
    }
}
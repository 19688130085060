export const FETCH_USER_REQUESTED = "FETCH_USER_REQUESTED";
export const FETCH_USER_SUCCEEDED = "FETCH_USER_SUCCEEDED";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";

export const CLEAR_USER = "CLEAR_USER";

export function fetchUserRequested(){
    return{
        type: FETCH_USER_REQUESTED,
    }
}

export function fetchUserSucceeded(user){
    return{
        type: FETCH_USER_SUCCEEDED,
        payload: user
    }
}

export function fetchUserFailed(error){
    return{
        type: FETCH_USER_FAILED,
        payload: error
    }
}


export function clearUser(){
    return{
        type: CLEAR_USER
    }
}
import { Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'
import ASMImageInput from '../../utilities/customForm/ASMImageInput'
import ASMTextAreaInput from '../../utilities/customForm/ASMTextAreaInput'
import PostService from '../../services/postService'
import './css/CreatePost.css'
import { useDispatch } from 'react-redux'
import { CREATE_POST, closePopup, openPopup } from '../../store/actions/popupAction'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse'


export default function CreatePost() {
    const dispatch = useDispatch()
    const [imgData, setImgData] = useState();
    const [open, setOpen] = useState(false)
    const [isClicked, setIsClicked] = useState(false);


    const initalValues = {
        content: '',
        image: undefined
    }

    async function handleCreatePost(values, resetForm) {
        setIsClicked(true)
        const postService = new PostService()
        try {
            const res = await postService.createPost(values.content, values.image)
            handleSuccessResponse(res)
            setOpen(false)
            postService.getFeedPosts()(dispatch) // TODO : post oluşturma profile alındığında, userprofile olarak güncellenecek
            resetForm({ values: '' })
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    function handleCloseModal(resetForm) {
        setOpen(false)
        resetForm({ values: '' })
        setImgData('')
        dispatch(closePopup(CREATE_POST))
    }

    function handleOpenModal(){
        setOpen(true)
        dispatch(openPopup(CREATE_POST))
    }


    return (
        <Formik
            initialValues={initalValues}
            onSubmit={(values, { resetForm }) => handleCreatePost(values, resetForm)}
        >
            {({ setFieldValue, handleSubmit, resetForm }) => (
                <Modal
                    as='form'
                    className='ui form'
                    onSubmit={handleSubmit}
                    onClose={() => handleCloseModal(resetForm)}
                    onOpen={() => handleOpenModal()}
                    open={open}
                    closeOnDimmerClick={false}
                    trigger={<Button positive className="button"> Gönderi Oluştur <Icon name='plus' style={{ marginLeft: '5px' }} /></Button>}

                >
                    <Modal.Header>Post Oluştur</Modal.Header>
                    <Modal.Content >
                        <ASMImageInput name="image" placeholder="Resim" value="" setFieldValue={setFieldValue}
                            imgName={"image"} imgData={imgData} setImgData={setImgData} ></ASMImageInput>
                        <ASMTextAreaInput name="content" placeholder="İçerik"></ASMTextAreaInput>
                    </Modal.Content>

                    <div style={{ margin: '2em 1.5em 1em', float: 'right' }}>
                        <Button negative onClick={() => handleCloseModal(resetForm)}>
                            İptal Et
                        </Button>
                        {!isClicked
                            ? <Button positive type='submit' >Oluştur</Button>
                            : <Button positive loading disabled>Oluştur</Button>
                        }
                    </div>
                </Modal>
            )
            }
        </Formik >
    )
}

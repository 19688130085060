export const FETCH_LOGINED_USER_REQUESTED = "FETCH_LOGINED_USER_REQUESTED";
export const FETCH_LOGINED_USER_SUCCEEDED = "FETCH_LOGINED_USER_SUCCEEDED";
export const FETCH_LOGINED_USER_FAILED = "FETCH_LOGINED_USER_FAILED";

export const CLEAR_LOGINED_USER = "CLEAR_LOGINED_USER";

export function fetchLoginedUserRequested(){
    return{
        type: FETCH_LOGINED_USER_REQUESTED,
    }
}

export function fetchLoginedUserSucceeded(loginedUser){
    return{
        type: FETCH_LOGINED_USER_SUCCEEDED,
        payload: loginedUser
    }
}

export function fetchLoginedUserFailed(error){
    return{
        type: FETCH_LOGINED_USER_FAILED,
        payload: error
    }
}


export function clearLoginedUser(){
    return{
        type: CLEAR_LOGINED_USER
    }
}
import React, { useEffect, useState } from 'react'
import AuthService from '../services/authService';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import logo from "../assets/images/applogo.jpeg"
import background from "../assets/images/background.jpeg"
import ASMTextInput from '../utilities/customForm/ASMTextInput';
import ASMPasswordInput from '../utilities/customForm/ASMPasswordInput';
import { Formik, Form } from 'formik';
import { handleErrorResponse } from '../utilities/axiosResponse/AxiosErrorResponse'
import { handleSuccessResponse } from '../utilities/axiosResponse/AxiosSuccessResponse'
import { useDispatch, useSelector } from 'react-redux';


export default function Register() {
  const authService = new AuthService();
  const [isClicked, setIsClicked] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {auth} = useSelector(state => state.auth);

  useEffect(() => {
    if (Object.keys(auth).length !== 0) {
      authService.writeTokenToCookie(auth.token, auth.expirationDate)
      navigateAndReload()
    }
  }, [dispatch])

  function navigateAndReload() {
    navigate("/")
    window.location.reload()
  }

  const handleRegister = async ({ login, email, password }) => { // TODO : login-register test
    await authService.register(login, email, password)(dispatch)
  }

  const initalLoginValues = {
    login: '',
    email: '',
    password: ''
  }

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundAttachment: "fixed", height: "101.4vh" }}>
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
            <Image src={logo} circular /> <span style={{ color: "#2FBDFB" }}>Yeni Hesap Oluşturun</span>
          </Header>
          <Formik
            initialValues={initalLoginValues}
            validator={() => ({})}
            onSubmit={(values) => handleRegister(values)}
          >
            <Form className='ui form'>
              <Segment stacked style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                <ASMTextInput name='login' placeholder='Kullanıcı Adı' style={{ color: "#2FBDFB" }} />
                <ASMTextInput name='email' placeholder='Email' style={{ color: "#2FBDFB" }} />
                <ASMPasswordInput name='password' placeholder='Şifre' style={{ color: "#2FBDFB" }} autoComplete="on" />
                {!isClicked && <Button type='submit' fluid size='large' style={{ background: "#2FBDFB" }}>Kayıt Ol</Button>}
              </Segment>
            </Form>
          </Formik>
          <Message style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            Zaten Hesabınız Var Mı ? <Link to={"/login"}>Giriş Yapın</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </div>
  )
}
import React, { useState } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import ASMPasswordInput from '../../utilities/customForm/ASMPasswordInput'
import { handleSuccessResponse } from '../../utilities/axiosResponse/AxiosSuccessResponse'
import { handleErrorResponse } from '../../utilities/axiosResponse/AxiosErrorResponse'
import UserService from '../../services/userService'

export default function PasswordResetForm({ email }) {
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false)

    const passwordInital = {
        newPassword: '',
        newPasswordConfirm: ''
    }

    async function handleResetPassword(values) {
        const userService = new UserService();
        setIsClicked(true)
        try {
            const res = await userService.resetPassword({
                email: email,
                newPassword: values.newPassword,
                newPasswordConfirm: values.newPasswordConfirm
            })
            handleSuccessResponse(res)
            navigate('/')
        } catch (err) {
            handleErrorResponse(err)
        }
        finally {
            setIsClicked(false)
        }
    }

    return (
        <Formik
            initialValues={passwordInital}
            onSubmit={(values) => handleResetPassword(values)}
        >
            <Form className='ui form'>
                <Grid>
                    <Grid.Column width={3} verticalAlign='middle'>Yeni Şifre : </Grid.Column>
                    <Grid.Column width={10}><ASMPasswordInput name='newPassword' placeholder='Şifre'></ASMPasswordInput></Grid.Column>
                </Grid>
                <Grid>
                    <Grid.Column width={3} verticalAlign='middle'>Yeniden Yeni Şifre : </Grid.Column>
                    <Grid.Column width={10}><ASMPasswordInput name='newPasswordConfirm' placeholder='Şifre'></ASMPasswordInput></Grid.Column>
                </Grid>

                <div style={{ marginTop: '2em', float: 'right' }}>
                    <Button secondary as={Link} to={'/'}>Giriş Ekranına Dön</Button>
                    {
                        !isClicked
                            ? <Button primary type='submit'>Şifreyi Sıfırla</Button>
                            : <Button primary loading disabled>Şifreyi Sıfırla</Button>
                    }
                </div>

            </Form>
        </Formik>
    )
}

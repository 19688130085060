import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import "./css/ProfileStyles.css"
import UserPosts from '../components/post/UserPosts'
import UserInformations from '../components/user/UserInformations'
import OtherUsersBasicInformations from '../components/user/OtherUsersBasicInformations'


// profil kısmı - posts kısmı - sağ kısım 3 farklı parçaya bölünecek
export default function LoginedUserProfile() {
  return (
    <div>
      <Grid>
        <Grid.Column width={4}>
          <UserInformations />
        </Grid.Column>
        <Grid.Column width={9}>
          <UserPosts />
        </Grid.Column>
        <Grid.Column width={3}>
          <OtherUsersBasicInformations />
        </Grid.Column>
      </Grid >
    </div >
  )
}



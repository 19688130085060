import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useEffect, useState } from 'react';
import Feed from './pages/Feed'
import Login from './pages/Login'
import AuthService from './services/authService'
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import Register from './pages/Register';
import UserProfile from './pages/UserProfile'
import LoginedUserProfile from './pages/LoginedUserProfile'
import Navi from './components/navbar/Navi';
import { Button, Container, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Helpers/Loader'
import VerifyEmail from './pages/VerifyEmail';
import { ToastContainer } from 'react-toastify';
import DirectMessage from './pages/Message';
import AdminPanel from './pages/AdminPanel';
import ManageUser from './components/admin-panel/pages/user/ManageUser'
import ManageRole from './components/admin-panel/pages/role/ManageRole';
import ResetPassword from './pages/ResetPassword';

function App() {
  const authService = new AuthService()
  const dispatch = useDispatch();
  const location = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthorizedToAccess, setIsAuthorizedToAccess] = useState(false);
  const [open, setOpen] = useState(false)

  const authState = useSelector(state => state.auth);
  const popupState = useSelector(state => state.popup);
  const loginedUserState = useSelector(state => state.loginedUser)

  useEffect(() => {

    authService.verify()
      .then(() => setIsAuthenticated(true))
      .catch(() => setIsAuthenticated(false))


    if (loginedUserState.loginedUser.userRoles) {
      authService.verifyAdmin()
        .then(() => setIsAdmin(true))
        .catch(() => setIsAdmin(false))
    }

    if (loginedUserState.loginedUser.userRoles && !isAuthorizedToAccess) {
      setIsAuthorizedToAccess(loginedUserState.loginedUser.userRoles.includes("Admin") || loginedUserState.loginedUser.userRoles.includes("Aga"))

      if (!isAuthorizedToAccess) {
        setOpen(true)
      }
    }


    if (popupState.isOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [dispatch, popupState, loginedUserState])



  let loading = authState.loading;
  return (
    <div className="App">
      {loading
        ? <Loader />
        : <div>

          <Container textAlign='center'>
            <Routes>
              <Route exact path="/resetpassword" element={<ResetPassword />} />
              <Route exact path="/verifyemail/:token" element={<VerifyEmail />} />
            </Routes>
          </Container>


          {isAuthenticated && isAuthorizedToAccess &&
            < div >
              {!location.pathname.startsWith('/verifyemail/') && <Navi isAdmin={isAdmin} />}
              <Container className='main'>
                <div style={popupState.isOpened ? { filter: 'blur(2px)' } : null}>
                  <Routes>
                    <Route exact path="/" element={<Feed />} />
                    <Route exact path="/feed" element={<Feed />} />
                    <Route exact path="/profile" element={<LoginedUserProfile />} />
                    <Route exact path="/profile/:userId" element={<UserProfile />} />
                    <Route exact path="/message" element={<DirectMessage />} />
                  </Routes>
                </div>
              </Container>
            </ div>
          }

          {isAuthenticated && !isAuthorizedToAccess && <div>
            <Navi />
            <Modal
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              closeOnDimmerClick={false}
              closeOnEscape={false}
            >
              <Modal.Header>UYARI !!</Modal.Header>
              <Modal.Content>
                Kullanıcınız başarıyla oluşturuldu ancak site henüz kapalı betadadır.
                Erişim istemek için <Link to={'mailto:anibiriktiriyorum-destek@outlook.com'}>anibiriktiriyorum-destek@outlook.com</Link> adresine mail atabilirsiniz.
                <br />
                Giriş Ekranına Dönmek için <Link onClick={() => authService.logout()}>tıklayınız</Link>.
              </Modal.Content>
            </Modal>
          </div>
          }


          {isAdmin &&
            <div style={popupState.isOpened ? { filter: 'blur(2px)' } : null}>
              <Container>
                <Routes>
                  <Route exact path="/admin" element={<AdminPanel />} />
                  <Route exact path="/admin/manage/users/:userId" element={<ManageUser />} />
                  <Route exact path="/admin/manage/roles/:roleId" element={<ManageRole />} />
                </Routes>
              </Container>
            </div>}


          {!isAuthenticated && <div>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
            </Routes>
          </div>}

        </div>
      }
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        limit={5}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div >
  );
}

export default App;

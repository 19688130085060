import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import RoleService from '../../../../services/roleService'
import { handleErrorResponse } from '../../../../utilities/axiosResponse/AxiosErrorResponse'
import { Button, Header, Label, Table } from 'semantic-ui-react';

export default function ManageRoles() {
  const params = useParams();
  const [role, setRole] = useState({})

  useEffect(() => {
    const roleService = new RoleService();
    roleService.getRoleForManage(params.roleId)
      .then(res => setRole(res.data.data))
      .catch(err => handleErrorResponse(err))
  }, [params])

  return (
    <div>
      <Header textAlign='center' size='huge'>Rol Detayları</Header>

      Rol Adı :
      <Label size='huge' style={{ background: 'none', color: 'black' }}>{role.name}</Label>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Kullanıcı Adı</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Roller</Table.HeaderCell>
            <Table.HeaderCell collapsing>Manage</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {role.userInformations && role.userInformations.map((user) => (
            <Table.Row key={user.id}>
                <Table.Cell>{user.login}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                  {
                    user.roles.map((role, index) => (
                      <div key={index}>
                        {role}
                        <br />
                      </div>
                    ))
                  }
                </Table.Cell>
                <Button primary as={Link} to={`/admin/manage/users/${user.id}`}>Navigate User</Button>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h3></h3>
    </div >
  )
}

import { toast } from 'react-toastify';

const successToast = (message) => toast.success(message);

export function handleSuccessResponse(res){
    successToast(res.data.message)
}

export function handleSuccessResponseAndDispatch(res) {
    return (dispatch, method) =>  {
        dispatch(method(res.data.data))
        successToast(res.data.message)
    }    
}
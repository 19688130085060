import { FETCH_USER_REQUESTED, FETCH_USER_SUCCEEDED, FETCH_USER_FAILED, CLEAR_USER } from '../actions/userAction'
import { user } from '../initalValues/initals'

const initalState = {
    loading: false,
    user: user,
    error: ''
}

export default function userReducer(state = initalState, { type, payload }) {
    switch (type) {
        case FETCH_USER_REQUESTED:
            return {
                ...state,
                loading: true
            }

        case FETCH_USER_SUCCEEDED:
            return {
                ...state,
                loading: false,
                user: payload
            }

        case FETCH_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error
            }

        case CLEAR_USER:
            return {
                ...state,
                user: {}
            }

        default:
            return state;
    }
}
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Card, Grid, Header, Image, Label } from 'semantic-ui-react'
import AdminService from '../../../../services/adminService'
import RoleService from '../../../../services/roleService'
import { Link, useParams } from 'react-router-dom';
import { handleErrorResponse } from '../../../../utilities/axiosResponse/AxiosErrorResponse'
import { Form, Formik } from 'formik';
import ASMTextInput from '../../../../utilities/customForm/ASMTextInput';
import ASMTextAreaInput from '../../../../utilities/customForm/ASMTextAreaInput';
import ASMSelectInput from '../../../../utilities/customForm/ASMSelectInput';
import { handleSuccessResponse } from '../../../../utilities/axiosResponse/AxiosSuccessResponse';


const UPDATE_USER_FORM = "UPDATE_USER_FORM"
const MAIL_SEND_FORM = "MAIL_SEND_FORM"
const WARN_USER_FORM = "WARN_USER_FORM"

export default function ManageUser() {
    const adminService = new AdminService();

    const params = useParams();

    const [user, setUser] = useState({});
    const [currentForm, setCurrentForm] = useState("");
    const [isClicked, setIsClicked] = useState(false)
    const [roles, setRoles] = useState([])


    useEffect(() => {
        adminService.getSelectedUser(params.userId)
            .then(res => setUser(res.data.data))
            .catch(err => handleErrorResponse(err))

        const roleService = new RoleService();
        roleService.getAllRoles()
            .then((res) => setRoles(res.data.data.map(role => ({ text: role.name, value: role.name }))))
            .catch(err => handleErrorResponse(err))

    }, [params.userId, currentForm])


    const updateInitalValues = {
        login: user.login,
        email: user.email,
        roles: user.userRoles
    }

    const mailInitalValues = {
        subject: '',
        body: ''
    }

    const warnInitalValues = {
        subjext: '',
        body: ''
        // Kategori
        // Rapor nedeni resmi
    }

    async function handleUpdateUser(values) {
        setIsClicked(true)
        try {
            const res = await adminService.updateUser({
                id: params.userId,
                login: values.login,
                email: values.email,
                roles: values.roles
            });
            handleSuccessResponse(res)
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    async function handleSendMail(values, resetForm) {
        setIsClicked(true)
        try {
            const res = await adminService.sendMail({
                userId: params.userId,
                subject: values.subject,
                body: values.body
            });
            handleSuccessResponse(res)
            resetForm({ values: '' })
        } catch (err) {
            console.log(err);
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    async function handleWarnMail(values) {
        setIsClicked(true)
        try {
            console.log(values);
            // handleSuccessResponse(res)
        } catch (err) {
            handleErrorResponse(err)
        } finally {
            setIsClicked(false)
        }
    }

    return (
        <div>
            <Grid>
                <Grid.Column width={6}>
                    <Card>
                        <Image src={user.profilePhotoPath} size='medium' />
                        <Card.Content>
                            <Card.Header>
                                {user.login}
                            </Card.Header>
                            <Card.Meta>
                                Joined: {user.createDate}
                            </Card.Meta>
                            <Card.Description>{user.email}</Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            {
                                user.userRoles && user.userRoles.map((role, index) => (
                                    <div key={index}>
                                        {role}
                                        < br />
                                    </div>
                                ))
                            }

                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={10}>
                    <Button as={Link} to={`/profile/${user.id}`} primary>Profile Git</Button>
                    <Button secondary onClick={() => setCurrentForm(UPDATE_USER_FORM)}>Bilgileri Düzenle</Button>
                    <Button positive onClick={() => setCurrentForm(MAIL_SEND_FORM)}>Mail Gönder</Button>
                    <Button negative onClick={() => setCurrentForm(WARN_USER_FORM)}>Uyarı Yap</Button>

                    <br />
                    {
                        currentForm === UPDATE_USER_FORM &&
                        <div>
                            <Header size='huge' textAlign='center' style={{ marginTop: '2em' }}>Kullanıcıyı Güncelle</Header>
                            <Formik
                                initialValues={updateInitalValues}
                                onSubmit={values => handleUpdateUser(values)}
                            >
                                <Form className='ui form'>

                                    <Grid verticalAlign='middle'>
                                        <Grid.Column width={4}>
                                            Kullanıcı Adı :
                                        </Grid.Column>
                                        <Grid.Column width={12}>
                                            <ASMTextInput name="login" placeholder="Kullanıcı Adı" />
                                        </Grid.Column>
                                    </Grid>

                                    <Grid verticalAlign='middle'>
                                        <Grid.Column width={4}>
                                            Email :
                                        </Grid.Column>
                                        <Grid.Column width={12}>
                                            <ASMTextInput name="email" placeholder="Email" />
                                        </Grid.Column>
                                    </Grid>

                                    <Grid verticalAlign='middle'>
                                        <Grid.Column width={4}>
                                            Roller :
                                        </Grid.Column>
                                        <Grid.Column width={12}>
                                            <ASMSelectInput id="roles" name="roles" placeholder="Rol Seçin" options={roles} />
                                        </Grid.Column>
                                    </Grid>


                                    {
                                        !isClicked
                                            ? <Button type='submit' positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Güncelle </Button>
                                            : <Button loading disabled positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Güncelle </Button>
                                    }
                                </Form>
                            </Formik >
                        </div>
                    }


                    {
                        currentForm === MAIL_SEND_FORM &&
                        <div>
                            <Header size='huge' textAlign='center' style={{ marginTop: '2em' }}>Mail Gönder</Header>
                            <Formik
                                initialValues={mailInitalValues}
                                onSubmit={(values, { resetForm }) => {
                                    handleSendMail(values, resetForm)
                                }}
                            >
                                <Form className='ui form'>
                                    Konu :
                                    <ASMTextInput name="subject" placeholder="Konu" />

                                    İçerik :
                                    <ASMTextAreaInput name="body" placeholder="İçerik" />
                                    {
                                        !isClicked
                                            ? <Button type='submit' positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Gönder </Button>
                                            : <Button loading disabled positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Gönder </Button>
                                    }
                                </Form>
                            </Formik>
                        </div>
                    }

                    {
                        currentForm === WARN_USER_FORM &&
                        <div>
                            <Header size='huge' textAlign='center' style={{ marginTop: '2em' }}>Uyarı Yap</Header>
                            <Formik
                                initialValues={warnInitalValues}
                                onSubmit={values => handleWarnMail(values)}
                            >
                                <Form className='ui form'>
                                    Konu :
                                    <ASMTextInput name="subject" placeholder="Konu" />

                                    İçerik :
                                    <ASMTextAreaInput name="body" placeholder="İçerik" />
                                    {
                                        !isClicked
                                            ? <Button type='submit' positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Gönder </Button>
                                            : <Button loading disabled positive style={{ position: 'absolute', right: 0, marginTop: '2em' }}> Gönder </Button>
                                    }
                                </Form>
                            </Formik>
                        </div>
                    }
                </Grid.Column>
            </Grid>
        </div>
    )
}
